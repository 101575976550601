console.log("custom js webpack");
jQuery(document).ready(function ($) {
  $(".menu-toggle").on("click", function () {
    $(".header-menu").toggleClass("menu-open");
  });

  /* Woocommerce AJAX update cart count */
  $(document.body).on("added_to_cart", function (response) {
    updateCartCounter();
  });

  // Update cart count function
  function updateCartCounter() {
    $.ajax({
      type: "GET",
      url: wc_cart_params.ajax_url,
      data: {
        action: "get_cart_count",
      },
      success: function (response) {
        $(".cart-count").html(response);
      },
    });
  }
});

/* Move price custom */
jQuery(document).ready(function ($) {
  $("form.variations_form")
    .on("found_variation", function (event, variation) {
      if (variation.price_html) {
        $(".product_pricing").html(variation.price_html);
      }
    })
    .on("reset_data", function () {
      $(".product_pricing").html(""); // Reset price HTML when no variation is selected
    });
});

/* Product tabs custom js */
jQuery(document).ready(function ($) {
  $(document).on("click", ".tabs li", function () {
    // Get the ID of the tab content to display
    var tabName = $(this).data("tab");

    // Hide all tab content and remove "active" class from all tabs
    $(".tab-content-wrapper").hide();
    $(".tabs li").removeClass("active");

    // Show the clicked tab content and add "active" class to the clicked tab
    $("#" + tabName).show();
    $(this).addClass("active");
  });

  // Optionally, trigger a click on the first tab to make it active by default
  $(".tabs li:first").click();
});

/* Timeline animation */
jQuery(document).ready(function ($) {
  // Function to animate elements
  function animateElements(container) {
    var lineContainer = $(container).find("#line-container");
    var line = lineContainer.find("#line");
    var circles = lineContainer.find(".circle");
    var images = $(container).find(".timeline-images .timeline-img");
    var descriptions = $(container).find(
      ".timeline-descriptions .timeline-desc"
    );
    var windowWidth = $(window).width();

    // Reset styles before animation
    circles.css("opacity", "0");
    images.css("opacity", "0");
    descriptions.css("opacity", "0");

    // Determine whether to animate width or height of the line
    if (windowWidth >= 768) {
      // Adjust this breakpoint as needed
      line.css("width", "0").animate({ width: "100%" }, 4000);
    } else {
      line.css("height", "0").animate({ height: "100%" }, 4000);
    }

    // Circles and content animation
    circles.each(function (index) {
      var delay = 500 + index * 1000;
      $(this).delay(delay).animate({ opacity: 1 }, 500);
      $(images[index]).delay(delay).animate({ opacity: 1 }, 500);
      $(descriptions[index]).delay(delay).animate({ opacity: 1 }, 500);
    });
  }

  // Function to check if element is in viewport
  function isElementInView(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  // Function to handle scroll event
  function handleScroll() {
    $(".timeline-container").each(function () {
      var $this = $(this);
      if (isElementInView($this)) {
        if (!$this.data("in-view")) {
          // console.log("Timeline container entering view");
          animateElements($this);
          $this.data("in-view", true); // Mark as in view
        }
      } else {
        if ($this.data("in-view")) {
          // console.log("Timeline container leaving view");
          $this.data("in-view", false); // Reset the in-view status
        }
      }
    });
  }

  // Attach scroll event listener
  $(window).on("scroll", handleScroll);

  // Initial check
  handleScroll();
});
